const Keys = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  SPACE_BAR: 32,
  ENTER: 13,
  ESC: 27
}

module.exports = {
  Keys: Keys,
  //Provides an easy way to trigger a callback based on any number of keyboard keys
  handleKeys(keys, callback) {
    return function(event) {
      if (keys.includes(event.keyCode)) {
        callback(event)
      }
    }
  },
  //Changes the visibility of an element with support for accessibility
  setElementVisibility(element, makeVisible) {
    if (makeVisible === undefined) {
      //If we are called without a visible parameter, check if we are currently visible.
      //If we are currently not-visible, this will toggle us to visible or vice-versa
      makeVisible = element.classList.contains('is-hidden')
    }

    if (makeVisible) {
      element.removeAttribute('aria-hidden')
      element.removeAttribute('hidden')
      element.classList.remove('is-hidden')
    }
    else {
      element.setAttribute('aria-hidden', true)
      element.setAttribute('hidden', true)
      element.classList.add('is-hidden')
    }
  }
}
