import qwest         from 'qwest'
import { log }       from './log'
import navbarElement from '../components/navbar/navbar-element'

/**
 * Gets the configured tenant master url.
 * This must NOT be called before the navbar has had a chance to actually be configured.
 *
 * As such, do NOT use qwest.base = getTenantMasterService()
 *
 * @returns {string}
 */
const getTenantMasterService = () => {
  const navbarConfig = navbarElement.navbarConfig || {}
  const navbarServices = navbarConfig.services || {}
  const tenantMasterUrl = navbarServices.tenantMasterApi

  if (tenantMasterUrl) {
    return tenantMasterUrl
  }

  //If it wasn't defined in the config, fallback to v1 impl
  let environmentFQDN = 'prod.aws-prod.psn.inmar.com'
  if (window.location.hostname === 'localhost') {
    environmentFQDN = 'preprod.aws-nonprod.psn.inmar.com'
  }
  else if(window.location.hostname.indexOf('.aws-') > -1) {
    environmentFQDN = window.location.host.split('.').filter((item, index) => index !==0).join('.')
  }

  return `https://tenant-master-api.${environmentFQDN}/api/v1`
}

const getOptions = async function(){
  const token = await window.inm.login.getToken()

  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    attempts: 3
  }
}

export default {
  getTenantInfo: async function(tenantId){
    if(!tenantId){
      return Promise.reject('No tenant ID')
    }
    const options = await getOptions()
    return qwest.get(`${getTenantMasterService()}/tenants/${tenantId}`, null, options)
      .then(resp => JSON.parse(resp.response)[0] || {})
      .catch(log.error)
  },
  getUserTenantList: async function(){
    const options = await getOptions()
    // this call does not use the userId to get tenants anymore, but it
    // still expects _something_ for the userId. As such, "nouserid" is sent to return results.
    return qwest.get(`${getTenantMasterService()}/users/nouserid/tenants`, null, options)
      .then(resp => JSON.parse(resp.response))
      .catch(log.error)
  },
  setUserCurrentTenant: async function(userId, tenantId){
    if (!userId || !tenantId) {
      return Promise.reject('No user and/or tenant ID')
    }
    const options = await getOptions()
    return qwest.post(`${getTenantMasterService()}/users/${userId}/tenants/${tenantId}/set_current`, null, options)
      .then(resp => JSON.parse(resp.response).id_token)
      .catch(log.error)
  },
}
