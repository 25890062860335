import './components/namespaced-theme-components.styl'
import tenantSwitchModal from './components/tenant-switch-modal/tenant-switch-modal'
import navbarElement from './components/navbar/navbar-element'

/**
 * Adds a global storage event listener, which will reload the page for all
 * non-active tabs, whenever a specific key is updated in localStorage
 */
window.addEventListener('storage', e => {
  // ie11 has a bug in which it triggers the storage event even on the focused tab. 
  // `hasFocus` will prevent this from firing in those cases
  if((e.key === 'portal_navbar_tenant_id') && !document.hasFocus()){
    location.reload()
  }
})

// bootstrap navbar's initial placeholder-dom and events
// wait for the login to be initialized so that we can access the config for the navbar
if(window.inm && window.inm.login && window.inm.login.isInitialized){
  navbarElement.loadPlaceholderDom()
}
else if (window.inm && window.inm.login && window.inm.login.on){
  window.inm.login.on('login-initialized', () => navbarElement.loadPlaceholderDom())
}
else{
  navbarElement.loadPlaceholderDom()
}

const portalNavbarApi = {
  load: navbarElement.load.bind(navbarElement),
  update: navbarElement.update.bind(navbarElement),
  modal: tenantSwitchModal,
  signout() {
    let hasTenantSupport = true // default to true, as it will break backwards compatibility otherwise
    if (window.inm && window.inm.login && window.inm.login.getDriverConfig) {
      hasTenantSupport = window.inm.login.getDriverConfig().hasTenantSupport
    }
    window.inm.login.logout()
    if (hasTenantSupport) {
      localStorage.removeItem('portal_navbar_tenant_id')
      window.location.reload()
    }
  },
  chooseMenuItem: function(menuItem){
    let event = new CustomEvent('b2bnavbar:chooseMenuItem')
    event.data = { url: menuItem } // we are using data here to mimic what turbolinks does, since we already look for "data.url" for other logic
    document.dispatchEvent(event)
  },
}

if (!window.inm) {
  window.inm = {}
}
if (!window.inm.navbar) {
  window.inm.navbar = portalNavbarApi
}

const navbarComponent = window.inm.navbar

export default navbarComponent