import portalConfigurations from '../portal-configs'

export default {
  getConfigByName(portalName){
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
          if (portalConfigurations.hasOwnProperty(portalName))
            resolve(portalConfigurations[portalName])
          else 
            reject(`Configuration for portal name ${portalName} was not found.`)
        }, 250)
    })
  },
}
