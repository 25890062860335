import {Keys, handleKeys, setElementVisibility} from '../../services/event-utils'

export default {
  setupEventHandlers() {
    const megaMenu        = document.querySelector(".js-portalnav__mega-menu")
    const megaMenuLabel   = document.querySelector(".js-portalnav__mega-menu-label")

    const handleMenuInteract = event => {
      setElementVisibility(megaMenu)
      let show = megaMenuLabel.classList.contains('is-hidden')
      megaMenuLabel.classList.toggle('is-hidden', !show) //We only want to hide the mega-menu top-wedge, so we don't use setElementVisibility which would set aria info
      megaMenuLabel.classList.toggle('is-open', show)
      megaMenuLabel.setAttribute('aria-expanded', show)
      
      // give menu a chance to start to show before we add the active focus.
      setTimeout(() => {
        let activeEl = megaMenu.querySelector('.js-portalnav__mega-menu__app.is-active')
        if(activeEl){
          activeEl.focus()
        }
      }, 100)
      event.stopPropagation()
      if (event.type === 'keydown') {
        event.preventDefault()
      }
    }

    const handleMenuImplicitClose = event => {
      setElementVisibility(megaMenu, false)
      megaMenuLabel.classList.add('is-hidden') //We only want to hide the mega-menu top-wedge, so we don't use setElementVisibility which would set aria info
      megaMenuLabel.classList.remove('is-open')
      megaMenuLabel.setAttribute('aria-expanded', false)
    }

    //We attached to the document instead of the body because turbolinks eats our handlers
    document.addEventListener('click', handleMenuImplicitClose)
    document.addEventListener('keydown', handleKeys([Keys.ESC], handleMenuImplicitClose))

    megaMenuLabel.addEventListener('click', handleMenuInteract)
    megaMenuLabel.addEventListener('keydown', handleKeys([Keys.SPACE_BAR, Keys.ENTER], handleMenuInteract))

    //If the menu or any of its children are clicked, don't want the event bubble up to the <body>.
    //If it were to bubble up, the menu would be closed even though we are interacting with it.
    //However, if the element clicked would cause a navigation due to it being an anchor with an href, we allow
    // the event to continue to propagate
    megaMenu.addEventListener('click', event => {
      let willNavigate = false
      for(let i in event.path){
        let element = event.path[i]
        if(element.getAttribute && element.getAttribute('href')){
          willNavigate = true
        }
      }
      if (!willNavigate) {
        event.stopPropagation()
      }
    })
  },
  setupEventHandlersNoTurbolinks(updateBreadcrumbsCallback) {
    const appLinks = document.querySelectorAll('.js-portalnav__mega-menu__app')
    for (let appLink of appLinks) {
      appLink.addEventListener('click', updateBreadcrumbsCallback(appLink))
    }
  },
  generateMenu(config) {
    const megaMenuContainer = document.querySelector(".js-portalnav__mega-menu-container")
    const megaMenu = document.querySelector(".js-portalnav__mega-menu")
    const categoryTemplates = config.navigation.map(category => this.generateCategory(category.title, category.children, category.uri))
    const categoryCount = categoryTemplates.length
    const filterBarNode = document.querySelector(".js-portalnav__mega-menu__filter")
    megaMenu.innerHTML = categoryTemplates.join('\n')

    if (megaMenu.hasChildNodes()) {
      const firstCategoryTitleNode = megaMenu.children[0].querySelector('.js-portalnav__mega-menu__category__title')
      firstCategoryTitleNode.appendChild(filterBarNode)
    }
    if (config.menuMode === 'dense') {
      megaMenuContainer.classList.add('portalnav__mega-menu-container--dense')
      if(categoryCount < 4){
        megaMenuContainer.classList.add(`portalnav__mega-menu-container--limit-${categoryCount}`)
      }
    }
  },
  generateCategory(title, apps, default_uri) {
    if (!apps) {
      apps = [{title: "Root Application", uri: default_uri}]
    }
    const generatedAppTemplates = apps.map(this.generateApp)

    return `
      <section class="portalnav__mega-menu__category js-portalnav__mega-menu__category" data-title="${title}">
        <div class="portalnav__mega-menu__category__title js-portalnav__mega-menu__category__title">
          ${title}
        </div>
        <div class="portalnav__mega-menu__category__apps">
          ${generatedAppTemplates.join("\n")}
        </div>
      </section>`
  },

  generateApp(app) {
    let icon = `<figure class="portalnav__mega-menu__app__icon--placeholder" alt=''></figure>`
    if (app.icon) {
      // icon = `<svg class="portalnav__mega-menu__app__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path class="a" d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm0,34a15.77,15.77,0,0,1-6-1.18V24.6L21,21l-4,3,13-5-9-5V12L12,8.4V7H10V31.84A16,16,0,1,1,18,34Z"/></svg>`
      // icon = `<svg class="portalnav__mega-menu__app__icon">
      //           <use xlink:href="${app.icon}"></use>
      //         </svg>`

      //Find a way to fix coloring
      icon = `<img class="portalnav__mega-menu__app__icon" src="${app.icon}"  alt=''/>`
    }

    return `
      <a class="portalnav__mega-menu__app js-portalnav__mega-menu__app" href="${app.uri}" role="menuitem" title="${app.title}">
        ${icon}
        <div class="portalnav__mega-menu__app__info">
          <b class="portalnav__mega-menu__app__title">${app.title}</b>
        </div>
      </a>`
  },

  generateUserMenu(config) {
    const userMenu = document.querySelector(".js-portalnav__user-info__menu-items")
    const menuItemTemplates = (config.items || []).map(menuItem => this.generateMenuItem(
      menuItem.title || 'Unknown Item',
      menuItem.uri || '',
      menuItem.class || ''
    ))

    userMenu.innerHTML = menuItemTemplates.join('\n')

  },

  generateMenuItem(title, uri, className) {
    //Only include the href attribute if the uri was provided.
    //This way, we can not provide an href and instead use .js- class hooks for the buttons.
    const hrefAttribute = uri ? `href="${uri}"` : ''

    return `
      <a class="portalnav__user-info__action ${className}" title="${title}" ${hrefAttribute} role="menuitem" tabindex="0">
        ${title}
      </a>`
  }
}
