import 'b2b-portal-loading-indicator'
import tingle from 'tingle.js'

import tenantManager       from '../../services/tenant'
import session             from '../../services/session'
import { log }             from '../../services/log'
import { Keys, handleKeys} from '../../services/event-utils'

import modalTemplate from './modal-template.html'
import './modal-styles.styl'

// setup variables to cache needed elements for re-use
let tenantSelectElement
let changeTenantButtonElement
let modal
let changingTenantLoader

const elementSelectors = {}
elementSelectors.modal           = '.js-portalnav__modal'
elementSelectors.modalBox        = `${elementSelectors.modal} .tingle-modal-box`
elementSelectors.notificationMsg = '.js-portalnav__modal__message'
elementSelectors.changeTenantBtn = '.js-portalnav__modal__change-tenant-btn'
elementSelectors.tenantName      = '.js-portalnav__modal__tenant-name'

//Prepended with the 'select' html element to differentiate between the native select and
// the possibly enhanced Choices.js version
elementSelectors.tenantSelect = 'select.js-portalnav__modal__tenant-select'


// define functions to initialize variable or return already cached element
const getTenantSelectElement = () => {
  if(!tenantSelectElement) {
      tenantSelectElement = document.querySelector(elementSelectors.tenantSelect)
  }
  return tenantSelectElement
}

const getChangeTenantButtonElement = () => {
  if(!changeTenantButtonElement) {
      changeTenantButtonElement = document.querySelector(elementSelectors.changeTenantBtn)
  }
  return changeTenantButtonElement
}

function autoFocusTenantSelectElement() {
  //If the select gets enhanced by choices.js, the .js class will be replicated to multiple elements.
  //The one we want to focus is the last one in the DOM that isn't hidden.
  //If the select _doesn't_ get enhanced by Choices.js, this will still work just fine.
  const selects = document.querySelectorAll('.js-portalnav__modal__tenant-select:not(.is-hidden)')
  const lastSelect = selects[selects.length - 1]

  if (lastSelect) {
    lastSelect.focus()
  }
}

export default {
  setupModal() {
    if (modal) {
      return
    }

    modal = new tingle.modal({
      footer: true,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: "Close",
      cssClass: ['portalnav__modal', elementSelectors.modal.replace('.', '')],
      onClose: () => {
        this.resetSelectTenantValue()
      },
      beforeClose: () => {
        return !changingTenantLoader || !changingTenantLoader.isPlaying
      }
    });

    modal.setContent(modalTemplate);
    modal.addFooterBtn('Cancel', `tingle-btn tingle-btn--default`, () => {
      modal.close();
    });
    modal.addFooterBtn('Switch to Tenant', `tingle-btn tingle-btn--primary ${elementSelectors.changeTenantBtn.replace('.', '')}`, () => {
      const tenantSelect = getTenantSelectElement()
      const selectedTenant = tenantSelect.value

      if (!session.selectedTenantHasChanged(selectedTenant)) {
        return
      }

      this.changeTenant()
    });

    //Readd the modal to the DOM after turbolinks removes it on navigation
    const modalElement = document.querySelector(elementSelectors.modal)
    document.addEventListener("turbolinks:load", function reinsertModalIntoDOM() {
      if (!document.body.contains(modalElement)) {
        document.body.appendChild(modalElement)
      }
    })

    //Add aria label to the close button for accessibility
    const modalCloseBtn = document.querySelector('.js-portalnav__modal .tingle-modal__close')
    modalCloseBtn.setAttribute('aria-label', 'Close change tenant modal')

    //Whenever the select changes, we need to determine if we can switch to the selected tenant.
    const tenantSelect = getTenantSelectElement()
    tenantSelect.addEventListener('change', event => {
      this.setChangeTenantButtonState(session.selectedTenantHasChanged(event.target.value))
    })

    //Setup the buttons added by tingle to be accessible via keyboard.
    const simulateClickOnKeydown = event => event.target.click()
    const buttons = document.querySelectorAll(`${elementSelectors.modal} button`)
    buttons.forEach(el => {
      el.addEventListener('keydown', handleKeys([Keys.SPACE_BAR, Keys.ENTER], simulateClickOnKeydown))
    })

    //Make sure the UI is in the correct initial state.
   this.setChangeTenantButtonState(false)
    this.resetNotification()
  },

  showModal() {
    modal.open()

    //Give the modal a bit to mount and for Choices.js to enhance the select (if the theme is loaded)
    setTimeout(autoFocusTenantSelectElement, 50)
  },

  changeTenant(){
    if (!changingTenantLoader) {
      changingTenantLoader = window.inm.loadingIndicator.create(elementSelectors.modalBox, {
        strokeColor: "#303584" //Inmar primary color
      })
    }
    changingTenantLoader.play()

    const selectedTenant = this.getSelectedTenantInfo()
    Promise.all([window.inm.login.getProfile()])
    .then(results => results[0].user_id)
		.then(userId => tenantManager.setUserCurrentTenant(userId, selectedTenant.tenantId))
    .then(newtoken => {
      let tenantId = this.getSelectedTenantInfo().tenantId
      localStorage.setItem('portal_navbar_tenant_id', tenantId)
      // set the id_token cookie to be the new token (login v0 implementation support)
      window.document.cookie = `id_token=${newtoken};path=/`

      // set the id_token localstorage to the new token (login v1 implementation support)
      try{
        const current_auth = JSON.parse(localStorage.getItem('inm-tokeninfo--auth0') || "{}")
        current_auth.id_token = newtoken
        localStorage.setItem('inm-tokeninfo--auth0', JSON.stringify(current_auth))
      }
      catch(e){
        log.error(e)
      }

      //Don't stop the loading indicator. The reload will handle it.
      location.reload()
    })
		.catch(error => {
      log.error(error)
      changingTenantLoader.stop()
      this.showNotification('error', 'There was an error changing tenants')
    })
  },

  resetSelectTenantValue(){
    const currentTenantId = session.getCurrentTenantId()

    this.setTenantSelectValue(currentTenantId)
		this.setChangeTenantButtonState(false)
  },

  addTenantSelectOptions(userTenants){
    const selectElement = getTenantSelectElement()

    // Remove all current tenants so we don't accidentally duplicate options
    // Note: Creating a new array because looping through and deleting elements from the live options array did not end well.
    for (const optionEl of [...selectElement.options]) {
      optionEl.parentNode.removeChild(optionEl)
    }

    userTenants.forEach(tenant => {
      const option = document.createElement("option")
      option.text = tenant.tenantName
      option.value = tenant.tenantId
      selectElement.options.add(option);
    })

    selectElement.disabled = false
  },

  setTenantSelectValue(currentTenantId){
    const selectElement = getTenantSelectElement()

    //This call is intentionally duplicated to handle a bug in Choices.js's handling of direct modification
    // of the select.value property. Both calls are needed to get Choices.js to propertly visualize the change.
    //Note: If the b2b-theme isn't loaded on the page then this won't matter.
    selectElement.value = currentTenantId
    selectElement.value = currentTenantId
  },

  getSelectedTenantInfo(){
    const selectElement = getTenantSelectElement()
    return {
      tenantName: selectElement.options[selectElement.selectedIndex].text,
      tenantId: selectElement.options[selectElement.selectedIndex].value
    }
  },

  updateCurrentTenantInUI(tenant){
    const tenantNameElement = document.querySelector(elementSelectors.tenantName)
    tenantNameElement.innerText = tenant.tenantName
    getTenantSelectElement().value = tenant.tenantId
  },

  showNotification(level, notificationMessage){
    const notificationEl = document.querySelector(elementSelectors.notificationMsg)

    notificationEl.classList.toggle('portalnav__message--warning', level === 'warning')
    notificationEl.classList.toggle('portalnav__message--danger', level === 'error')
    notificationEl.innerText = notificationMessage
  },

  resetNotification() {
    this.showNotification('warning', 'Changing tenants will clear any un-saved work and reload the app.')
  },

  setChangeTenantButtonState(setEnabled){
    const changeTenantButton = getChangeTenantButtonElement()

    if (setEnabled) {
      changeTenantButton.removeAttribute('disabled')
    }
    else {
      changeTenantButton.setAttribute("disabled", "disabled")
    }
  },
}
