import tenantManager from './tenant'
import tenantSwitchModal from '../components/tenant-switch-modal/tenant-switch-modal'
import { log, logThrow } from './log'

let authInfo = {
  user: {
    name: null,
    tenantList: [],
    inmarAssociate: false,
    hasTenantSupport: true
  },
  tenant: {}
}

export default {
  supportsTenants(){
    let hasSupport = true
    if (window.inm && window.inm.login && window.inm.login.getDriverConfig) {
      hasSupport = window.inm.login.getDriverConfig().hasTenantSupport
    }
    return hasSupport
  },
  getAuthInfo() {
    authInfo.user.hasTenantSupport = this.supportsTenants()

    if (authInfo.user.hasTenantSupport){
      // this includes user AND tenant info
      return this.setTenantInfo().then(() => authInfo)
    }
    return this.setUserInfo().then(() => authInfo)
  },
  setTenantInfo(){
    return this.setUserInfo()
      .then(profile => {
        authInfo.tenant.tenantId = profile.tenant
        localStorage.setItem('portal_navbar_tenant_id', authInfo.tenant.tenantId)
      })
      .then(() => tenantManager.getUserTenantList())
      .then(results => {
        tenantSwitchModal.resetNotification()
        //sort results
        let tenants = results.sort(function (a, b) {
          if (a.tenantName < b.tenantName) {
            return -1
          }
          if (a.tenantName > b.tenantName) {
            return 1
          }
          return 0
        })
        authInfo.user.tenantList = tenants
      })
      .catch(error => {
        log.warn(error)
        tenantSwitchModal.showNotification("error", 'The tenant list is temporarily unavailable')
        return []
      })
      .then(() => tenantManager.getTenantInfo(authInfo.tenant.tenantId))
      .then(tenant => {
        if (!tenant.tenantName) {
          logThrow.error(`Can't Find Tenant; AuthInfo: ${JSON.stringify(authInfo, null, 2)}`)
        } else {
          authInfo.tenant.tenantName = tenant.tenantName
          authInfo.tenant.tenantType = tenant.tenantType
        }
      })
      .catch(error => {
        log.warn(error)
        authInfo.tenant = {
          tenantName: "Can't Find Tenant"
        }
      })
  },
  setUserInfo(){
    return Promise.all([window.inm.login.getProfile()])  // wrap jquery promise in es6 promise.all
      .then(([profile]) => {
        if (profile.company === "Inmar") {
          authInfo.user.inmarAssociate = true
        }
        authInfo.user.username = profile.username || profile.email

        if (profile.given_name && profile.family_name) {
          authInfo.user.name = `${profile.given_name} ${profile.family_name}`
          authInfo.user.initials = `${profile.given_name[0]}${profile.family_name[0]}`
        } else if (profile.name) {
          let nameParts = profile.name.split(',')
          let lastName = nameParts[0]
          let firstName = ''
          if (nameParts.length > 1) {
            firstName = nameParts[1]
          }
          authInfo.user.name = `${firstName} ${lastName}`
          if (firstName != '') {
            authInfo.user.initials = `${firstName[0]}${lastName[0]}`
          } else {
            authInfo.user.initials = `${lastName.substring(0, 2)}`
          }
        } else if (profile.display_name) {
          authInfo.user.name = profile.display_name
          let nameParts = profile.display_name.split(' ')
          if (nameParts.length > 1) {
            authInfo.user.initials = `${nameParts[0]}${nameParts[1]}`
          }
          else {
            authInfo.user.initials = `${profile.display_name.substring(0, 2)}`
          }
        }
        else {
          authInfo.user.initials = `${profile.email.substring(0, 2)}`
        }
        return profile
      })
  },
  reLoadTenantList() {
    return tenantManager.getUserTenantList()
      .then(results => {                                                        // store authInfo.user.tenantList
        tenantSwitchModal.resetNotification()
        authInfo.user.tenantList = results
        tenantSwitchModal.addTenantSelectOptions(results)
        tenantSwitchModal.setTenantSelectValue(this.getCurrentTenantId())
      })
      .catch(error => {
        log.warn(error)
        tenantSwitchModal.showNotification('error', 'The tenant list is temporarily unavailable')         // display error message
      })
  },
  selectedTenantHasChanged(selectedTenantId) {
    return selectedTenantId !== authInfo.tenant.tenantId
  },
  getCurrentTenantId() {
    return authInfo.tenant.tenantId
  }
}
