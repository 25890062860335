const log = {
  error(e) {
    if (window.inm && window.inm.logger) {
      window.inm.logger.error(e)
      return
    }
    console.error(e) // eslint-disable-line no-console
  },
  warn(e){
    if (window.inm && window.inm.logger) {
      window.inm.logger.warn(e)
      return
    }
    console.warn(e) // eslint-disable-line no-console
  }
}

const logThrow = {
  error(e) {
    if (!(e instanceof Error)) {
      e = new Error(e)
    }

    if (window.inm && window.inm.logger) {
      window.inm.logger.error(e)
    }

    // allow the thrown error to continue
    throw e
  }
}


export {log, logThrow}