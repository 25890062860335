/**
 * Stubbed Portal Manage API data store
 *
 * @return {}
 */
export default {
  'DemoPortal': {
    navbar: {
      displayName: '<b>Demo</b>Portal',
      navigation: [
        { title: 'Demo Home', uri: '/'},
        { 
          title: 'Demo Pg2',  
          children: [
            { title: "Subnav 1", uri: "/demo" },
            { title: "Subnav 2", uri: "/" },
            { title: "Subnav 3", uri: "/demo" }
          ] 
        }
      ]
    }
  }
}
