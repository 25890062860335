// note: on `no-turbolinks` builds, this will actually import `noop.js` in the lib root from webpack aliasing
import turbolinks from 'turbolinks'

// templates
import './navbar-styles.styl'
import navbarTemplate from './navbar-template.html'
import tenantSwitchModal from '../tenant-switch-modal/tenant-switch-modal'

// VMs
import megaMenuManager     from '../mega-menu'
import userDropdownManager from '../user-dropdown-menu'

// Services
import portalManager from '../../services/portal-manager'
import {log, logThrow} from '../../services/log'
// import session from '../../services/session'

let _navbarNode = null
let _isDataLoadedAndInjectedIntoNavbar = false
let _currentConfig
const _domChangesBookmarkElementId = 'hidden-dom-change-checker'

function getNavbarNode() {
  if (!_navbarNode) {
    _navbarNode = document.getElementById('portal-navbar')
  }

  return _navbarNode
}

function getNavbarSetting(setting) {
  return getNavbarNode().getAttribute('data-' + setting)
}


function removeNode(selector) {
  let elem = getNavbarNode().querySelector(selector)
  if (elem) {
    elem.parentNode.removeChild(elem)
  }
}

/*
 * Have navbar changes to the DOM been destroyed?
 */
function haveDomChangesBeenDestroyed() {
  return document.getElementById(_domChangesBookmarkElementId) === null
}

/*
 * Creates a hidden element and inserts it into the navbar element.
 *
 * This element acts as a simple way to check if menu items need to be re-added after the user has hit the back button.
 * The back button is a special case where some state is preserved from the original page load (HTML returned from the server, global JS variables),
 * but other state is not preserved (HTML elements added by this navbar).
 */
function addDomChangeBookmark() {
  if (haveDomChangesBeenDestroyed()) {
    return
  }

  const hiddenElement = document.createElement('div')
  hiddenElement.setAttribute('id', _domChangesBookmarkElementId)
  hiddenElement.setAttribute('style', 'display: none')
  document.getElementById('portal-navbar').appendChild(hiddenElement)
}

export default {
  placeholderInjectionSetup: false,
  navbarLoadedPromise: null,
  navbarConfig: null,
  isPlaceholderReady() {
    const navbarNode = getNavbarNode()
    return this.placeholderInjectionSetup && navbarNode && navbarNode.hasChildNodes()
  },
  isDataLoaded() {
    return Boolean(this.navbarConfig)
  },
  isLoaded() {
    return this.isPlaceholderReady() && this.isDataLoaded() && getNavbarNode().getAttribute('is-loaded')
  },
  loadPlaceholderDom() {
    if (this.placeholderInjectionSetup) {
      return
    }
    this.placeholderInjectionSetup = true

    const injectNavbarPlaceholder = () => {
      if (!getNavbarNode()) {
        log.error("Could not find the portal-navbar placeholder by id on the page!")
        return
      }

      //Check and see if turbolinks is already attached to the DOM.
      //If it is, do not restart it.
      if (__TURBOLINKS_SETTING__ == 'use' && !window.Turbolinks) {
        turbolinks.start()
        turbolinks.setProgressBarDelay(10)
      }

      let hasTenantSupport = true
      if (window.inm && window.inm.login && window.inm.login.getDriverConfig) {
        hasTenantSupport = window.inm.login.getDriverConfig().hasTenantSupport
      }

      getNavbarNode().innerHTML = navbarTemplate

      if(hasTenantSupport){
        tenantSwitchModal.setupModal()
      }
      else{
        removeNode('.js-portalnav__user-context__current-tenant')
        removeNode('.js-portalnav__tenant-toggle__label')
        removeNode('#change-tenant-modal')
      }

      megaMenuManager.setupEventHandlers()
      userDropdownManager.setupEventHandlers()

      //Set detectable major version
      getNavbarNode().setAttribute('data-major-version', __LIBRARY_MAJOR_VERSION__)

      this.injectDataIntoNavbar()
    }

    //DOMContentLoaded only executes once, so if we aren't still loading, immediately inject the placeholder.
    if (document.readyState !== "loading") {
      injectNavbarPlaceholder()
    }
    else {
      document.addEventListener("DOMContentLoaded", injectNavbarPlaceholder)
    }

  },
  load(config) {
    let self = this

    if (!this.navbarLoadedPromise) {
      // We've never run, so get things started.
      this.loadData(config)
    } else if (_isDataLoadedAndInjectedIntoNavbar && haveDomChangesBeenDestroyed()) {
      // We have been here before, but we're here again because someone hit the back button. We need to start things over again.
      this.loadData(config)
    }

    addDomChangeBookmark()

    return this.navbarLoadedPromise
      .then(() => {
        if (!_isDataLoadedAndInjectedIntoNavbar && window.inm.login && window.inm.login.on){
          window.inm.login.on('login-success', function(e){
            self.update(_currentConfig) // pass the current config in, which is set in load data.
          })
          _isDataLoadedAndInjectedIntoNavbar = true
        }
      })
  },
  update(config){
    this.loadData(config)
  },
  loadData(config) {
    _currentConfig = config
    this.navbarLoadedPromise = this.setNavbarConfig(config).then(() => {
      userDropdownManager.asyncLoad()
      this.injectDataIntoNavbar()
    })
    .catch(logThrow.error)
  },
  setNavbarConfig(config) {
    let getConfigPromise = this.getPortalConfig(config)

    return Promise.all([getConfigPromise])
      .then(([cfg]) => this.navbarConfig = cfg.navbar)
      .catch(logThrow.error)
  },
  getPortalConfig(config){
    let getConfigPromise

    if (typeof config === 'string')
      getConfigPromise = portalManager.getConfigByName(config)
    else if (config.then)
      getConfigPromise = config
    else
      logThrow.error('b2b-portal-navbar: Invalid navbar config provided. Please provide a promise which resolves to a navbar config object.')

    return getConfigPromise
  },
  injectDataIntoNavbar() {
    /*
      Injection of data into the navbar is dependent on 2 parallel processes:
       1) The Navbar being injected into the page (placeholder)
       2) The portal config promise being resolved.

       If either of these things hasn't happened yet, leave this function.
       Each process calls this function, so after they're both done, this will succeed.
     */
    if (!this.isDataLoaded() || !this.isPlaceholderReady()) {
      return
    }

    const navbarTitleNode = getNavbarNode().querySelector(".js-portalnav__title")
    if(navbarTitleNode){
      navbarTitleNode.innerHTML = this.navbarConfig.displayName
    }

    const navbarTitleLink = getNavbarNode().querySelector(".js-portalnav-title-link")
    if (navbarTitleLink && this.navbarConfig.titleLink){
      // change to anchor tag
      let newEl = document.createElement('a')
      newEl.setAttribute('href', this.navbarConfig.titleLink)
      newEl.className = navbarTitleLink.className
      newEl.innerHTML = navbarTitleLink.innerHTML
      navbarTitleLink.parentNode.replaceChild(newEl, navbarTitleLink);
    }

    megaMenuManager.generateMenu(this.navbarConfig)
    if(this.navbarConfig.userMenu)
      megaMenuManager.generateUserMenu(this.navbarConfig.userMenu)
    else
      removeNode('.js-portalnav__user-info__menu-items')

    //If turbolinks is disabled, we add hooks to MegaMenu items make sure we manually update the breadcrumbs
    const turbolinksDisabled = (getNavbarSetting('turbolinks') === 'false') || (__TURBOLINKS_SETTING__ == 'none')
    if (turbolinksDisabled) {
      megaMenuManager.setupEventHandlersNoTurbolinks(rootTarget => event => {
        //We add a data.url to the event to bring it inline with a turbolinks:load event format.
        //We get the href from the rootTarget instead of event.target because a child could have been the target.
        const navigatingTo = rootTarget.getAttribute('href')
        event.data = { url: navigatingTo }

        this.updateBreadcrumbDisplay(event)
      })
    }

    if (this.navbarConfig.disableTenantSwitching) {
      let switchTenantLink = getNavbarNode().querySelector('.js-portalnav__user-info__tenant__actions__switch-tenant')

      switchTenantLink.classList.add('hide')
      switchTenantLink.setAttribute('hidden', true)
      switchTenantLink.setAttribute('aria-hidden', true)
      switchTenantLink.setAttribute('role', 'menuitem')
    }

    this.updateBreadcrumbDisplay()
    getNavbarNode().setAttribute('is-loaded', 'true')

    document.addEventListener('turbolinks:load', this.updateBreadcrumbDisplay.bind(this))
    document.addEventListener('b2bnavbar:chooseMenuItem', this.updateBreadcrumbDisplay.bind(this))
  },
  // this function generates secondary nav items and sets the active class on appropriate menu items
  updateBreadcrumbDisplay(event) {
    /*
      Intra Spa routing is used for when Spas use the top level nav while also using a Router
      within the spa that takes advantage of location.hash to provide SPA routing without reloads.
      An example of such a router is Vue-Router.

      Intra-SPA-routing:
        Example:
          Style Guide Spa
        Url Breakdown:
          location.href:     https://b2b-styleguide.preprod.aws-nonprod.psn.inmar.com/#/Layout/basic
          location.origin:   https://b2b-styleguide.preprod.aws-nonprod.psn.inmar.com
          location.pathname: /
          location.hash:     #/layout/basic
        Explanation:
          The Style Guide uses the top-level nav to represent different sections of the style guide. The spa itself
          acts like a portal, but is a monolith instead of a collection of micro-SPAs. It uses Vue-Router in combination
          with the top-level nav to create this experience, and as such, requires intra-spa-routing="true"

      Portal-routing:
        Example:
          Promotion Operations Portal w/ Fee Manager Spa
        Url Breakdown Example:
          location.href:     https://rebates-promotions-operations-portal-spa.preprod.aws-nonprod.psn.inmar.com/fees/management/#/manage-client/16546
          location.origin:   https://rebates-promotions-operations-portal-spa.preprod.aws-nonprod.psn.inmar.com
          location.pathname: /fees/management/
          location.hash:     #/manage-client/16546
        Explanation:
          The above spa, while using an intra-spa Router (Vue-Router), does not use the top-level nav as navigation tool
          within the spa. The Fee Manager spa lives at the /fees/management/ path, so as long as the user is at this spa,
          the top level link needs to be active. As such, intra-spa-routing is not enabled for this spa.
     */

    const shouldIntraSpaRoute = getNavbarSetting('intra-spa-routing') === 'true'
    const suppliedUri = getNavbarSetting('page-uri') // optionally supply a uri to force the active item highlight in the sub menu
    const pathname = suppliedUri || (shouldIntraSpaRoute ? window.location.hash : window.location.pathname)
    const destUri = (event) ? event.data.url.replace(window.location.origin, '') : pathname
    const secondaryNavSearchResult = this.getSecondaryNavItems(destUri, this.navbarConfig.navigation)
    const activeItems = this.getActiveLinks(secondaryNavSearchResult)

    if(activeItems.length){
      getNavbarNode().querySelector(".js-portalnav__mega-menu-label__category").innerHTML = activeItems[0].id
      getNavbarNode().querySelector(".js-portalnav__mega-menu-label__section").innerHTML = `<b>${activeItems[1].id}</b>`
      let currentActive = getNavbarNode().querySelector('.js-portalnav__mega-menu__app.is-active')
      if(currentActive){
        currentActive.classList.remove('is-active')
      }
      let category = `.js-portalnav__mega-menu__category[data-title='${activeItems[0].id}']`
      let activeEl = getNavbarNode().querySelector(`${category} .js-portalnav__mega-menu__app[title='${activeItems[1].id}']`)
      if(activeEl){
        activeEl.classList.add('is-active')
      }
    }
    else{
      getNavbarNode().querySelector(".js-portalnav__mega-menu-label__category").innerHTML = ''
      getNavbarNode().querySelector(".js-portalnav__mega-menu-label__section").innerHTML = ''
      let currentActive = getNavbarNode().querySelector('.js-portalnav__mega-menu__app.is-active')
      if (currentActive) {
        currentActive.classList.remove('is-active')
      }
    }
  },
  getSecondaryNavItems(targetUri, navigationConfig) {
    const result = {
      activePrimaryNavItem: null,
      secondaryNavItems: []
    }

    // traverse the config object to determine if secondary nav items need to be rendered
    // and which nav items should be active
    navigationConfig.forEach(config => {
      if(config.uri && (config.uri === targetUri || config.uri === `#${targetUri}` || targetUri.startsWith(config.uri))) {
        result.activePrimaryNavItem = config
      }
      if(config.children){
        config.children.forEach(child => {
          // if we find a matching uri on the child we know we need all of the child's siblings to
          // be secondary nav items and the child is active and the parent is active
          if(child.uri && (child.uri === targetUri)){
            // assigning parent as active
            result.activePrimaryNavItem = config
            // assigning secondary nav items
            config.children.forEach(item => {
              result.secondaryNavItems.push({
                active:false,
                itemConfig:item
              })
            })
            // find the index of the secondary nav item that should be active
            const activeIndex = result.secondaryNavItems.findIndex((element, index, array) => {
              return element.itemConfig.uri === targetUri
            })
            // set the active property
            result.secondaryNavItems[activeIndex].active = true
          }
          // if there is a secondary level, and there is not a uri match at the secondary level,
          // we need to check the third level for a match if there is a third level
          // * Note:  a child with no uri (or title) but with children represents the case
          // where no secondary nav is needed and the config includes third level items so that
          // individual spas can leverage the config to render nav-tabs.
          else if(child.uri && child.children){
            child.children.forEach(grandchild => {
              // if we find a uri match at the third level we know the grandparent is active
              // and the parent and its siblings are the secondary nav items and the parent is active
              if(grandchild.uri && (grandchild.uri === targetUri)){
                result.activePrimaryNavItem = config
                config.children.forEach(item => {
                  result.secondaryNavItems.push({
                    active:false,
                    itemConfig:item
                  })
                })
                // find the index of the parent in the result
                const activeIndex = result.secondaryNavItems.findIndex((element, index, array) => {
                  return element.itemConfig.title === child.title
                })
                // set active
                result.secondaryNavItems[activeIndex].active = true
              }
            })
          }
        })
      }
    })
    return result
  },
  getActiveLinks(secondaryNavSearchResult) {
    const activeItems = []
    if(secondaryNavSearchResult.activePrimaryNavItem) {
      const activeItem = {
        grandparent: false,
        id: secondaryNavSearchResult.activePrimaryNavItem.title
      }
      if(secondaryNavSearchResult.secondaryNavItems.length) {
        activeItem.grandparent = true
      }
      activeItems.push(activeItem)
    }

    secondaryNavSearchResult.secondaryNavItems.forEach(item => {
      if(item.active) {
        activeItems.push({
          grandparent: false,
          id: item.itemConfig.title
        })
      }
    })
    return activeItems
  }
}
