import tenantManager from '../../services/tenant'
import Session from '../../services/session'
import { log, logThrow } from '../../services/log'
import tenantSwitchModal  from '../tenant-switch-modal/tenant-switch-modal'
import { Keys, handleKeys, setElementVisibility } from '../../services/event-utils'

export default {
    asyncLoad() {
        //If the login component isn't installed, we are in an anonymous navbar state.
        if (!window.inm.login) {
            return Promise.resolve()
        }
        // getToken kicks off the login, which we need in order to display the user menu
        return window.inm.login.getToken().then(asyncRenderAuthenticatedUserMenu) 
    },
    setupEventHandlers() {
      const userMenu        = document.querySelector('.js-portalnav__user-context__user-menu-label')
      const userMenuContent = document.querySelector('.js-portalnav__user-menu')

      const implicitClose = event => {
        userMenu.classList.remove('is-open')
        userMenu.setAttribute('aria-expanded', false)
        setElementVisibility(userMenuContent, false)
      }

      const userMenuInteraction = event => {
        userMenu.classList.toggle('is-open')
        userMenu.setAttribute('aria-expanded', userMenu.classList.contains('is-open'))
        setElementVisibility(userMenuContent, userMenu.classList.contains('is-open'))

        event.stopPropagation()
        if (event.type === 'keydown') {
            event.preventDefault()
        }
      }
      //We attached to the document instead of the body because turbolinks eats our handlers
      document.addEventListener('click', implicitClose)
      document.addEventListener('keyup', handleKeys([Keys.ESC], implicitClose))

      // attach event to open user profile dropdown on interaction
      userMenu.addEventListener('click', userMenuInteraction)
      userMenu.addEventListener('keydown', handleKeys([Keys.SPACE_BAR, Keys.ENTER], userMenuInteraction))
    },
}

function asyncRenderAuthenticatedUserMenu() {
    setVisible()

    return Session.getAuthInfo().then(session => {
        addUserInfoToDOM(session.user)
        if (session.user.hasTenantSupport) {
          addTenantInfoToDOM(session)
        }
        else {
          removeTenantInfoFromDOM()
        }
    })
}

function setVisible() {
  const setToVisible = () => {
    const userWidgetWrapper = document.querySelector('.js-portalnav__user-widget-wrapper')
    setElementVisibility(userWidgetWrapper, true)
  }

  //DOMContentLoaded only executes once, so if we aren't still loading, immediately set widget to visible
  if (document.readyState !== "loading") {
    setToVisible()
  }
  else {
    document.addEventListener("DOMContentLoaded", setToVisible)
  }
}

function changeTenant(tenantId){
    const changingTenantLoader = window.inm.loadingIndicator.start('body')
    
    Promise.all([window.inm.login.getProfile()])
        .then(results => results[0].user_id)
        .then(userId => tenantManager.setUserCurrentTenant(userId, tenantId))
        .then(newToken => {
            // This is absolutely a hack till we get a better solution for tenancy. 
            // When we get a new token, we need to save it into the same
            // localstorage key that the auth0 driver uses to store the key, so that it can
            // retrieve profile information correctly
            let currentInfo = localStorage.getItem('inm-tokeninfo--auth0')
            if(currentInfo){
                currentInfo = JSON.parse(currentInfo)
                currentInfo.id_token = newToken
                localStorage.setItem('inm-tokeninfo--auth0', JSON.stringify(currentInfo))
            }
            localStorage.setItem('portal_navbar_tenant_id', tenantId)
        })
        .catch(log.error)
        .then(() => {
            changingTenantLoader.stop()
            location.reload()
        })
}

function addTenantsToDropdown(session){
    const toggleTenantMenuItem = document.querySelector('.js-portalnav__tenant-toggle__label')
    const userMenuLabel = document.querySelector('.js-portalnav__user-context__user-menu-label')
    const userMenu = document.querySelector('.js-portalnav__user-menu')

    const getMenuItems = () => Array.prototype.slice.call(userMenu.querySelectorAll('[role="menuitem"]'))
    const isMenuItem = el => el.getAttribute('role') === 'menuitem'

    if (toggleTenantMenuItem) {
        // add event listener for changing tenants
        toggleTenantMenuItem.addEventListener('click', event => {
            if (toggleTenantMenuItem.getAttribute('sandbox') === 'true') {
                changeTenant(toggleTenantMenuItem.getAttribute('liveid'))
            } else {
                changeTenant(toggleTenantMenuItem.getAttribute('sandboxid'))
            }
        })

        toggleTenantMenuItem.addEventListener('keydown', handleKeys([Keys.ENTER, Keys.SPACE_BAR], (event) => {
            if (toggleTenantMenuItem.getAttribute('sandbox') === 'true') {
                changeTenant(toggleTenantMenuItem.getAttribute('liveid'))
            } else {
                changeTenant(toggleTenantMenuItem.getAttribute('sandboxid'))
            }
            event.preventDefault()
        }))
    }

    if (userMenuLabel) {
        //If we are focusing the menu and press the upkey, close the menu.
        userMenuLabel.addEventListener('keydown', handleKeys([Keys.ARROW_UP], (event) => {
            if (userMenuLabel.className.includes('is-open')) {
                userMenuLabel.click()
                event.preventDefault()
            }
        }))

        //When focusing the userMenuLabel, if the downkey is pressed, open the menu. If it is open move into the menu to the first item.
        userMenuLabel.addEventListener('keydown', handleKeys([Keys.ARROW_DOWN], (event) => {
            const userMenuItems = getMenuItems()
            if (!userMenuLabel.className.includes('is-open')) {
                userMenuLabel.click()
                event.preventDefault()
            } else if (userMenuItems.length) {
                userMenuItems[0].focus()
                event.preventDefault()
            }
        }))
    }

    if (userMenu) {
        //If we press Enter or Space on an item, click it.
        userMenu.addEventListener('keydown', handleKeys([Keys.ENTER, Keys.SPACE_BAR], event => {
            if (isMenuItem(event.target)) {
                event.target.click()
                event.preventDefault()
            }
        }))

        //Handle up and down keys for navigating user menu
        userMenu.addEventListener('keydown', function (event) {
            const userMenuItems = getMenuItems()
            const currentItemIndex = userMenuItems.findIndex(item => item === event.target)
            if (currentItemIndex === -1) {
                return
            }

            if (event.keyCode === Keys.ARROW_UP) {
                const nextItem = userMenuItems[currentItemIndex - 1]
                if (nextItem) {
                    nextItem.focus()
                } else {
                    //If we are on the first item and ask to move up, move to the user menu label in the navbar.
                    userMenuLabel.focus()
                }
                event.preventDefault()
            }

            if (event.keyCode === Keys.ARROW_DOWN) {
                const nextItem = userMenuItems[currentItemIndex + 1]
                if (nextItem) {
                    nextItem.focus()
                }
                event.preventDefault()
            }
        })
    }

    try {

      if(!toggleTenantMenuItem) {
          return "addTenantsToDropdown failure"
      }

      let liveTenant, sandboxTenant
      if (session.tenant.tenantType === 'sandbox') {
          liveTenant = session.user.tenantList.find(function(tenant) {
              const upcaseTenantWithoutSandbox = session.tenant.tenantName.toUpperCase().replace(' SANDBOX', '')
              return tenant.tenantName.toUpperCase() === (upcaseTenantWithoutSandbox)
          })
          sandboxTenant = session.tenant
          toggleTenantMenuItem.setAttribute('sandboxid', sandboxTenant.tenantId)
          toggleTenantMenuItem.setAttribute('liveid', liveTenant.tenantId)

          toggleTenantMenuItem.innerHTML = `Switch from <b>Sandbox</b> to <b>Live Mode</b>`
          toggleTenantMenuItem.setAttribute('sandbox', true)

          toggleTenantMenuItem.setAttribute('aria-label', `Switch from sandbox to live mode for tenant ${session.tenant.tenantName}`)
      } else {
          sandboxTenant = session.user.tenantList.find(function(tenant) {
              return tenant.tenantName.toUpperCase() === (`${session.tenant.tenantName.toUpperCase()} SANDBOX`)
          })
          liveTenant = session.tenant
          toggleTenantMenuItem.setAttribute('sandboxid', sandboxTenant.tenantId)
          toggleTenantMenuItem.setAttribute('liveid', liveTenant.tenantId)

          toggleTenantMenuItem.innerHTML = `Switch from <b>Live Mode</b> to <b>Sandbox</b>`
          toggleTenantMenuItem.setAttribute('sandbox', false)
          
          toggleTenantMenuItem.setAttribute('aria-label', `Switch from live mode to sandbox for tenant ${session.tenant.tenantName}`)
      }

      return 'addTenantsToDropdown success'
    } catch(err) {
        if (err.toString().includes(`property 'tenantId' of undefined`)) {
            if (session.tenant.tenantId === undefined) {
                // only log a warning if it's actually necessary.
                if(Session.supportsTenants()){
                    // intentionally logging warning information for troubleshooting
                    log.warn(`Cannot identify current tenant, and so cannot identify corresponding tenant for sandbox toggling`)
                }
            } else {
                // intentionally logging warning information for troubleshooting
                // log.warn(`For current tenant (${session.tenant.tenantName}, ${session.tenant.tenantId}), cannot find corresponding tenant for sandbox toggling`)
            }

            if(Session.supportsTenants()){
                let youCannotToggleElement = document.createElement('div')
                youCannotToggleElement.classList.add('portalnav__user-info__action--disabled')
                youCannotToggleElement.innerText = 'Mode Switching Is Unavailable'

                toggleTenantMenuItem.parentNode.replaceChild(youCannotToggleElement, toggleTenantMenuItem)
            }
            else{
                toggleTenantMenuItem.parentNode.removeChild(toggleTenantMenuItem)
            }
        } else {
          logThrow.error(err)
        }
        return err
    }
    
}

function addTenantSwitchModalLink() {
    const tenantSwitchSelectorId = 'js-tenant-switch-button'

    //Don't add the item multiple times.
    if (document.getElementById(tenantSwitchSelectorId)) {
        return
    }

    const changeTenantAction = document.createElement("a")
    changeTenantAction.id = tenantSwitchSelectorId
    changeTenantAction.classList.add('portalnav__user-info__action')
    changeTenantAction.innerHTML = `Change tenant`
    changeTenantAction.setAttribute('role', 'link')
    changeTenantAction.setAttribute('tabindex', '0')
    changeTenantAction.setAttribute('role', 'menuitem')

    const tenantActions = document.querySelector('.js-portalnav__user-info__tenant__actions')
    if (!tenantActions) {
        return
    }

    tenantActions.appendChild(changeTenantAction)

    const showTenantSwitchModal = event => {
        tenantSwitchModal.showModal()
    }

    changeTenantAction.addEventListener('click', showTenantSwitchModal)
    changeTenantAction.addEventListener('keydown', handleKeys([Keys.ENTER, Keys.SPACE_BAR], showTenantSwitchModal))
}

function addTenantContext(tenant) {
    const tenantContext = document.querySelector('.js-portalnav__user-info__tenant')
    tenantContext.setAttribute('aria-hidden', false)
    tenantContext.removeAttribute('hidden')
    tenantContext.classList.remove('is-hidden')

    const tenantTitle = document.querySelector('.js-portalnav__user-info__tenant__title')
    const tenantName  = document.querySelector('.js-portalnav__user-info__tenant__name')

    if (tenant.tenantName === `Can't Find Tenant` && tenantContext) {
      removeTenantInfoFromDOM()
    } else {
        tenantName.innerHTML = tenant.tenantName
        tenantTitle.appendChild(tenantName)
    }
}

function addUserInfoToDOM(user) {
    const userNameInfo = document.querySelector('.js-portalnav__user-info__name')
    const userEmail = document.querySelector('.js-portalnav__user-info__username')
    userNameInfo.innerHTML = user.name
    userEmail.innerText = user.username || ''

    document.querySelector('.js-portalnav__user-context__user-menu-label').setAttribute('data-initials', user.initials || '')
}
function addTenantInfoToDOM(session){
    const tenantName = session.tenant.tenantName
    document.querySelector('.js-portalnav__user-context__current-tenant').innerText = tenantName !== `Can't Find Tenant` ? tenantName : ''
    tenantSwitchModal.updateCurrentTenantInUI(session.tenant)
    tenantSwitchModal.addTenantSelectOptions(session.user.tenantList)
    tenantSwitchModal.setTenantSelectValue(session.tenant.tenantId)

    //Only show the tenant-change system if the user has access to more than 1 tenant.
    if (session.user.inmarAssociate || session.user.tenantList.length > 1) {
        addTenantContext(session.tenant)
        addTenantSwitchModalLink()
        if (session.tenant.tenantType === 'sandbox') {
            let sandboxIndicator = document.querySelector('.js-portalnav__user-context__sandbox-tenant-indicator')
            setElementVisibility(sandboxIndicator, true)
        }
    } else {
        if (session.tenant.tenantType === 'sandbox') {
            let sandboxIndicator = document.querySelector('.js-portalnav__user-context__sandbox-tenant-indicator')
            setElementVisibility(sandboxIndicator, true)
        }
    }

    addTenantsToDropdown(session)
}

function removeTenantInfoFromDOM() {
    const tenantContext = document.querySelector('.js-portalnav__user-info__tenant')
    if (tenantContext) {
        tenantContext.parentNode.removeChild(tenantContext)
    }
}
